import getDateRangePickerConfig from "../configurations/daterangePicker";
import {carouselConfig} from "../configurations/carousel";

export default function initialize3rdApps() {
  $('#listingDaterangepicker').daterangepicker(getDateRangePickerConfig('AT'));
  $('#listingDaterangepicker').on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY'));
  });

  $(document).ready(function () {
    $('.default-carousel-name').slick(carouselConfig);
  });
}
