export default function registerCopyToClipboardd() {
  window.copyToClipboard = (input) => {
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand("copy");
    showCopyToClipboardPopup();
  };
}

function showCopyToClipboardPopup() {
  const popup = document.getElementById("copyToClipboardPopup");
  if (!popup.classList.contains('show')) {
    popup.classList.add("show");
    setTimeout(function () {
      popup.classList.remove("show")
    }, 2000);
  }
}
