
function initListingFormListeners() {
  reviewStepBeforeShowListener();
  enableEverythingAfterPageLoads();
  creditsListener();
  listingTypeChangesListener();
}

function reviewStepBeforeShowListener() {
  $("#stepperTabContent").on('reviewPageBeforeShow', function () {
    setTitleOnReviewStep();
    setDescriptionOnReviewStep();
    setPricesOnReviewStep();
    setDatetimeOnReviewStep();
    setAreasOnReviewStep();
    setImageOnReviewStep();
  });
}

function creditsListener() {
  $("body").on('DOMSubtreeModified', "#listingSettingContainer", function () {
    $('.tgl-new-listing-credits').text($('.listingSettingElement').length);
  });
}

function enableEverythingAfterPageLoads() {
  $('#tglIsRequestWrapper').find('input').prop('disabled', false);
}

function listingTypeChangesListener() {
  $('#tglIsRequestWrapper').find('input[name=type]').change(function () {
    const rental = this.getAttribute('data-rental');
    if ($(this).val() == "listing") {
      $('.tgl-additional-information-datetime').hide();
      $('.tgl-review-checkbox').hide();
      $('.tgl-credits-container').hide();
    } else {
      $('.tgl-additional-information-datetime').show();
      $('.tgl-review-checkbox').show();
      $('.tgl-credits-container').show();
    }

    $('#tglNewListingReviewStartTime').text("");
    $('#tglNewListingReviewEndTime').text("");

    if (rental == "false") {
      ListingStepper.listingSetting.hideIsRentalRadioButtons();
    } else {
      ListingStepper.listingSetting.showIsRentalRadioButtons();
    }
  });
}

function setTitleOnReviewStep() {
  $('#tglNewListingReviewTitle').text($('#tglNewListingTitle').val());
}

function setDescriptionOnReviewStep() {
  $('#tglNewListingReviewDescription').text($('#tglNewListingDescription').val());
}

function setPricesOnReviewStep() {
  const min = getPrice('tgl-price-min', true);
  $('#tglNewListingReviewPriceMin').text(min);

  const max = getPrice('tgl-price-max');
  $('#tglNewListingReviewPriceMax').text(max ? `- ${max}`: max);
}

function setDatetimeOnReviewStep() {
  $('#tglNewListingReviewDate').text($('#listingDaterangepicker').val());

  const startTime = $('#tglNewListingStartTime').val() == "" ? "<br/>00:00 -" : $('#tglNewListingStartTime').val();
  $('#tglNewListingReviewStartTime').empty();
  $('#tglNewListingReviewStartTime').append($.parseHTML(startTime));

  const endTime = $('#tglNewListingEndTime').val() == "" ? " 23:59" : $('#tglNewListingEndTime').val();
  $('#tglNewListingReviewEndTime').text(endTime);
}

function setAreasOnReviewStep() {
  const data = $('.tgl-js-select2').select2('data');
  const areas = data.map(item => '<span class="tgl-category-tag">' + $(item.element).data('custom') + '</span>').join(' ');
  $('#tglNewListingReviewAreas').empty();
  $('#tglNewListingReviewAreas').append($.parseHTML(areas));
}

function setImageOnReviewStep() {
  $('.tgl-new-listing-review-image').attr('src', $('#tglUploadImagePreviewId').attr('src'));
}

function getPrice(className, sortReverse=false) {
  let prices = getPrices(className);
  prices = prices.sort((a, b) => a - b);
  if (sortReverse) {
    prices = prices.reverse();
  }
  const price = prices.pop();
  if (price) {
    return `€ ${price}`
  }
  return '';
}

function getPrices(className) {
  return $(`.${className}`).map(function () {
    return parseFloat(this.value);
  }).get().filter(value => !isNaN(value));
}

export {initListingFormListeners}
