
function initAllDayListeners() {
  allDayCheckboxChangeListener();
}

function allDayCheckboxChangeListener() {
  $("#tglAllDay").change(function () {
    const timeInputs = $('#tglTimes').children('input');
    if (this.checked) {
      timeInputs.each(function () {
        $(this).val('');
        $(this).prop('disabled', true);
      })
    } else {
      timeInputs.each(function () {
        $(this).prop('disabled', false);
      })
    }
  });
}

export {initAllDayListeners}
