export const carouselConfig = {
  centerMode: true,
  centerPadding: '60px',
  slidesToShow: 1,
  infinite: false,
  variableWidth: true,
  swipe: false,
  responsive: [{
    breakpoint: 768,
    settings: {
      centerMode: true,
      centerPadding: '10px',
      slidesToShow: 1
    }
  }, {
    breakpoint: 480,
    settings: {
      centerMode: true,
      centerPadding: '10px',
      slidesToShow: 1
    }
  }
  ]
};
