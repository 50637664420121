class HideListingCardArea {
  constructor(listingCardSubtitle) {
    this.listingCardSubtitles = document.querySelectorAll(listingCardSubtitle);

    if (this.listingCardSubtitles) {
      this.analizySubtitle();

      window.addEventListener("resize", () => this.analizySubtitle());
    }
  }

  analizySubtitle() {
    for (let i = 0; i < this.listingCardSubtitles.length; ++i) {
      this.calculateCategoryWidth(this.listingCardSubtitles[i]);
    }
  }

  calculateCategoryWidth(el) {
    const areas = el.firstElementChild.children;
    let subtitleWidth = el.firstElementChild.offsetWidth;
    let areasWidth = 0;
    for (let i = 0; i < areas.length; i++) {
      areasWidth += areas[i].offsetWidth;
    }

    let display = "flex";
    if (areasWidth < subtitleWidth) {
      display = "none";
    }

    if (el.lastElementChild.tagName == "SPAN") {
      el.lastElementChild.style.display = display;
    }
  }
}

export default HideListingCardArea;