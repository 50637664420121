import {validateInputs} from './stepperFormValidations';
import {
  STEPPER_FORM_NAV_STEP_CLASS, ICON_STEP_OPEN, ICON_STEP_ACTIVE,
  ICON_STEP_DONE, ICON_BASE_PATH, TAB_ACTIVE_CLASSES
} from '../constants/stepperForm';

function Step(tab, navItem) {
  this.tab = tab;
  this.navItem = navItem;
}

Step.prototype.getNextStep = function () {
  const nextNavItem = this.navItem.nextAll(STEPPER_FORM_NAV_STEP_CLASS).first();
  const nextTabId = nextNavItem.attr('id').slice(0, -3);
  const nextTab = $(`#${nextTabId}`);
  return new Step(nextTab, nextNavItem);
};

Step.prototype.getPrevStep = function () {
  const prevNavItem = this.navItem.prevAll(STEPPER_FORM_NAV_STEP_CLASS).first();
  const prevTabId = prevNavItem.attr('id').slice(0, -3);
  const prevTab = $(`#${prevTabId}`);
  return new Step(prevTab, prevNavItem);
};

Step.prototype.isValid = function () {
  const formElements = this.tab.find('input:visible, select:visible, textarea:visible');
  return validateInputs(formElements);
};

Step.prototype.setDoneStep = function () {
  this.navItem.children('img').attr('src', `${ICON_BASE_PATH}${ICON_STEP_DONE}`);
  this.tab.removeClass(TAB_ACTIVE_CLASSES);
};

Step.prototype.setActiveStep = function () {
  this.navItem.children('img').attr('src', `${ICON_BASE_PATH}${ICON_STEP_ACTIVE}`);
  this.tab.addClass(TAB_ACTIVE_CLASSES);
};

Step.prototype.setOpenStep = function () {
  this.navItem.children('img').attr('src', `${ICON_BASE_PATH}${ICON_STEP_OPEN}`);
  this.tab.removeClass(TAB_ACTIVE_CLASSES);
};

function getActiveStep() {
  const activeTab = $('#stepperTabContent').children('.active');
  const activeTabId = activeTab.attr('id');
  const activeNavItem = $(`#${activeTabId}Nav`);
  return new Step(activeTab, activeNavItem);
}

function nextStep() {
  const activeStep = getActiveStep();
  if (activeStep.isValid()) {
    const nextStep = activeStep.getNextStep();
    activeStep.setDoneStep();
    triggerEventsBeforeChangeNextStep(activeStep, nextStep);
    nextStep.setActiveStep();
    scrollToTop();
  }
}

function prevStep() {
  const activeStep = getActiveStep();
  const prevStep = activeStep.getPrevStep();
  activeStep.setOpenStep();
  prevStep.setActiveStep();
  scrollToTop();
}

function triggerEventsBeforeChangeNextStep(activeStep, nextStep) {
  if (nextStep.tab.attr('id') === 'stepperTabreview') {
    nextStep.tab.trigger('reviewPageBeforeShow');
  }
}

function scrollToTop() {
  window.scrollTo(0, 0);
}

export {nextStep, prevStep};
