export default function getDateRangePickerConfig(locale) {
  return {
    autoUpdateInput: false,
    drops: "down",
    locale: locales[locale],
    minDate: new Date(),
    // maxSpan: {"days": 35}
  }
}

const atLocale = {
  format: 'DD-MM-YYYY',
  // separator: " - ",
  applyLabel: "Anwenden",
  cancelLabel: "Abbrechen",
  fromLabel: "Von",
  toLabel: "Bis",
  customRangeLabel: "Benutzerdefiniert",
  weekLabel: "W",
  daysOfWeek: [ "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober",
               "November", "Dezember"],
  firstDay: 1
};

const locales = {
  AT: atLocale
};
