require('select2');

class Select2 {

  constructor(selector, selectAllButtonId, unselectAllButtonId) {
    this.selector = selector;
    this.select2Settings = {
      tags: true,
      closeOnSelect: false,
      dropdownParent: $('#js-select2-container'),
      templateSelection: (selection) => this.templateSelection(selection),
      templateResult: (option) => this.templateResult(option)
    }
    this.selectElement = window.$(selector).select2(this.select2Settings);
    if (this.selectElement.length == 0) {
      return;
    }

    this.availableOptions = this.countAllAvailableOptions(this.selectElement);

    //init Listeners
    this.selectElement.on("select2:unselect", () => this.selectOpening());
    this.selectElement.on('select2:selecting', (e) => this.selectChildren(e.params.args.data.element));
    this.selectElement.on('select2:select', () => this.addHiddenCategoriesTag());
    this.selectElement.on('select2:unselect', () => this.addHiddenCategoriesTag());
    this.selectElement.on("select2:open", () => this.dropdownOpen());

    this.selectAllButton = window.$(selectAllButtonId);
    this.unselectAllButton = window.$(unselectAllButtonId);
    if(this.selectAllButton != null){
      this.selectAllButton.on("click", () => this.selectAll());
      this.unselectAllButton.on("click", () => this.unselectAll());
    }
    this.addHiddenCategoriesTag();
  }

  templateSelection(selection) {
    return $(selection.element).data('custom');
  }

  templateResult(option) {
    if (option && option.element && option.element.hasAttribute("optgroup")) {
      return window.$.parseHTML(`<strong>${option.text}</strong>`);
    }
    return option.text;
  }

  selectOpening() {
    this.selectElement.one('select2:opening', (ev) => { ev.preventDefault(); });
  }

  selectChildren(element) {
    if(element.hasAttribute("data-children")) {
      const selectedOptions = this.selectElement.val()
      const children = JSON.parse(element.getAttribute("data-children"));

      this.updateSelectElement([...selectedOptions, ...children]);      
      this.highlightOptionsInDropdown(children);
      return false;
    }
  }

  highlightOptionsInDropdown(children) {
    const options = document.getElementById("select2-tglMultipleSelect-results").querySelectorAll('li');
    for (let i = 0; i < options.length; i++) {
      const idChunks = options[i].id.split("-");
      const id = idChunks[idChunks.length - 1];

      if(children.indexOf(parseInt(id, 10)) > -1) {
        options[i].setAttribute("aria-selected", true);
      }
    }
  }

  dropdownOpen() {
    if (window.innerWidth <= 576) {
      document.querySelector(this.selector).scrollIntoView();
    }
  }

  selectAll() {
    const optionsIds = [];
    this.selectElement.find('option').map((index, option) => {
      if(!option.hasAttribute('optgroup') && option.hasAttribute('value')) {
        optionsIds.push(option.getAttribute("value"));
      }
    });
    this.updateSelectElement([...optionsIds]);
  }

  unselectAll() {
    this.updateSelectElement("");
  }

  updateSelectElement(val) {
    this.selectElement.val(val).trigger('change');
    this.addHiddenCategoriesTag();
  }

  addHiddenCategoriesTag() {
    if(this.selectElement.val().length == this.availableOptions.length) {
      this.selectAllButton.text("Alles Ausgewählt");
      this.selectAllButton.prop('disabled', true);
    } else {
      this.selectAllButton.text("Alle Auswählen");
      this.selectAllButton.prop('disabled', false);
    }

    if (this.selectElement.val().length < 10) {
      return;
    }
  
    const newLi = document.createElement("LI");   
    const bold = document.createElement('strong');
    const textnode = document.createTextNode(`+${this.selectElement.val().length - 9} Kategorien`); 
    
    bold.appendChild(textnode);
    newLi.className = "select2-selection__choice"; 
    newLi.appendChild(bold); 

    const selectionRenderedUl = document.querySelector('.select2-selection__rendered');
    selectionRenderedUl.insertBefore(newLi, selectionRenderedUl.childNodes[9]);
  }

  countAllAvailableOptions(selectElement) {
    const options = selectElement.children();
    const availableOptions = []
    for (let i = 0; i < options.length; i++) {
      if(!options[i].hasAttribute("optgroup")) {
        availableOptions.push(options[i]);
      }
    }

    return availableOptions;
  }

}

export default Select2;
