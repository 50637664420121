import loadImage from 'blueimp-load-image';
import {DEFAULT_PREVIEW_IMAGE_SELECTOR, DEFAULT_IMAGE_URL} from '../constants/uploadImage'

function changeImageSrcOnUploadInputChange(
    uploadInput,
    imagePreviewSelector = DEFAULT_PREVIEW_IMAGE_SELECTOR,
    defaultImageUrl = DEFAULT_IMAGE_URL
) {
  const previewImage = $(`${imagePreviewSelector}`);
  if (uploadInput.files && uploadInput.files[0]) {
    const file = uploadInput.files[0];

    getOrientation(file, (orientation) => {
      loadImage(file, (img) => {previewImage.attr('src', img.toDataURL());}, {orientation: orientation || true,});
    });
  } else {
    previewImage.attr('src', defaultImageUrl);
  }
}

function getOrientation(file, callback) {
  var reader = new FileReader();
  reader.onload = function (e) {

    var view = new DataView(e.target.result);
    if (view.getUint16(0, false) != 0xFFD8) {
      return callback(-2);
    }
    var length = view.byteLength, offset = 2;
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) {
        return callback(-1);
      }
      var marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xFFE1) {
        if (view.getUint32(offset += 2, false) != 0x45786966) {
          return callback(-1);
        }

        var little = view.getUint16(offset += 6, false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;
        for (var i = 0; i < tags; i++) {
          if (view.getUint16(offset + (i * 12), little) == 0x0112) {
            return callback(view.getUint16(offset + (i * 12) + 8, little));
          }
        }
      } else if ((marker & 0xFF00) != 0xFF00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    return callback(-1);
  };
  reader.readAsArrayBuffer(file);
}

export {changeImageSrcOnUploadInputChange}
