const requiredOptionText = "Du musst eine Auswahl treffen.";
const requiredFieldText = "Dieses Feld ist notwendig.";

const validations = {
  type : [{
    name: 'checkboxOrRadioRequired',
    errorMessage: requiredOptionText
  }],
  title : [{
    name: 'required',
    errorMessage: requiredFieldText
  }],
  description : [{
    name: 'required',
    errorMessage: requiredFieldText
  }],
  amount : [{
    name: 'required',
    errorMessage: requiredFieldText
  }],
  price_min : [{
    name: 'required',
    errorMessage: requiredFieldText
  }],
  price_max : [{
    name: 'required',
    errorMessage: requiredFieldText
  }],
  price_unit : [{
    name: 'required',
    errorMessage: requiredFieldText
  }],
  amount_unit : [{
    name: 'required',
    errorMessage: requiredFieldText
  }],
  category_id : [{
    name: 'required',
    errorMessage: requiredFieldText
  }],
  is_rental : [{
    name: 'checkboxOrRadioRequired',
    errorMessage: requiredOptionText
  }],
  area : [{
    name: 'requiredMultiple',
    errorMessage: requiredFieldText
  }],
  dates : [{
    name: 'rentalOptional',
    errorMessage: requiredFieldText
  }],
};

const validationRules = {
  required: {
    isValid: function (input) {
      return input.val();
    }
  },
  requiredMultiple: {
    isValid: function (input) {
      return input.val() && input.val().length;
    }
  },
  checkboxOrRadioRequired: {
    isValid: function (input) {
      return $(`input[name="${input.attr('name')}"]`).is(':checked');
    }
  },
  rentalOptional: {
    isValid: function (input) {
      const type = $('input[name=type]:checked').val();
      if (type == 'listing') {
        return true;
      }

      return input.val() && input.val().length;
    }
  }
};

function validateInputs(inputs) {
  const arrayInputs = $.makeArray(inputs);
  return arrayInputs.map(input => validateInput($(input))).every(item => item);
}

function validateInput(input) {
  const inputValidations = validations[getValidationName(input)] || [];
  return inputValidations.map(inputValidation => {
    if (validationRules[inputValidation.name].isValid(input, inputValidation)) {
      removeError(input);
      return true
    }
    showError(input, inputValidation.errorMessage);
    return false;
  }).every(item => item);
}

function getValidationName(input) {
  let validationName = input.attr('name') || '';
  const regex = /\[.+?\]/g;
  const matchedStrings = validationName.match(regex);
  if (matchedStrings && matchedStrings.length) {
    validationName = matchedStrings.slice(-1)[0];
  }
  return validationName.replace(/[\[\]]/g, '')
}

function showError(input, errorMessage) {
  const errorBox = getErrorBox(input);
  if (errorBox.length) {
    if (!errorBox.find('.tgl-invalid-feedback').length) {
      errorBox.append(createErrorElement(errorMessage))
    }
  } else {
    if (!input.next('.tgl-invalid-feedback').length) {
      input.after(createErrorElement(errorMessage));
    }
  }
}

function removeError(input) {
  getErrorBox(input).find('.tgl-invalid-feedback').remove();
  input.next('.tgl-invalid-feedback').remove();
}

function createErrorElement(text) {
  return $(`<span class="invalid-feedback tgl-invalid-feedback" role="alert"><strong>${text}</strong></span>`);
}

function getErrorBox(input) {
  let name = input.attr('name').replace(/[\[\]]/g, '');
  return $(`.${name}-error`);
}

export {validateInputs}
