import {nextStep, prevStep} from "../components/stepperForm";
import {STEPPER_FORM_NEXT_BUTTON_CLASS, STEPPER_FORM_PREV_BUTTON_CLASS, STEPPER_FORM_SUBMIT_BUTTON_CLASS} from "../constants/stepperForm";

function initStepperFormListeners() {
  stepperButtonsListeners();
  enableButtonsAfterPageLoads();
}

function stepperButtonsListeners() {
  $(`.${STEPPER_FORM_NEXT_BUTTON_CLASS}`).click(function () {
    nextStep();
  });

  $(`.${STEPPER_FORM_PREV_BUTTON_CLASS}`).click(function () {
    prevStep();
  });
}

function enableButtonsAfterPageLoads() {
  $(`.${STEPPER_FORM_PREV_BUTTON_CLASS}, .${STEPPER_FORM_NEXT_BUTTON_CLASS}, .${STEPPER_FORM_SUBMIT_BUTTON_CLASS}`).prop('disabled', false);
}

export {initStepperFormListeners}
