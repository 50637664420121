
export const STEPPER_FORM_NEXT_BUTTON_CLASS = 'tgl-stepper-next-step-button';
export const STEPPER_FORM_PREV_BUTTON_CLASS = 'tgl-stepper-prev-step-button';
export const STEPPER_FORM_SUBMIT_BUTTON_CLASS = 'tgl-stepper-submit';
export const STEPPER_FORM_NAV_STEP_CLASS = '.tgl-stepper-nav-step';
export const ICON_BASE_PATH = '/icons/newListing/';
export const ICON_STEP_DONE = 'step_done.svg';
export const ICON_STEP_ACTIVE = 'step_current.svg';
export const ICON_STEP_OPEN = 'step_open.svg';
export const TAB_ACTIVE_CLASSES = 'active show';
