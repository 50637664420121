/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import registerCopyToClipboard from "./components/copyToClipboard";

require('./bootstrap');
require('daterangepicker');
require('bootstrap-star-rating');
require('slick-carousel');

window.$ = $;

import initListeners from "./listeners";
import ListingSetting from "./components/ListingSetting";
import Select2 from "./components/Select2";
import registerCopyToClipboardd from "./components/copyToClipboard";
import initialize3rdApps from "./externalApps";
import HideListingCardArea from "./components/HideListingCardArea";

window.ListingStepper = {};

window.addEventListener('load', function () {
  ListingStepper.listingSetting = new ListingSetting("listingSettingContainer", "BaselistingSettingTemplet", "addListingId", "removeListing");
  $('[data-toggle="tooltip"]').tooltip();
  new Select2('.tgl-js-select2', '#select2-select-all', '#select2-unselect-all');
  new HideListingCardArea('.tgl-listing-card-subtitle');
});

initListeners();
registerCopyToClipboardd();
initialize3rdApps();
