class ListingSetting {

  constructor(containerId, templateId, addListingButtonId, removeListingButtonClass) {
    this.container = document.getElementById(containerId);
    this.template = document.getElementById(templateId);
    this.addListingButton = document.getElementById(addListingButtonId);
    this.removeListingButtonClass = removeListingButtonClass;
    this.templateClassName = 'listingSettingElement';
    this.counter = 0;

    if (this.container) {
      this.init();
    }

  }

  init() {
    this.initListeners();
    this.initTemplate();
  }

  initListeners() {
    this.addListingButton.addEventListener("click", () => this.appendTemplate());
    this.container.addEventListener("click", (e) => this.removeTemplate(e));
  }

  initTemplate() {
    this.template.parentElement.removeChild(this.template);
    this.template.style.display = 'block';
    if (this.noTemplateExist()) {
      this.appendTemplate(true);
    }
  }

  noTemplateExist() {
    return this.container.innerHTML.trim() === '';
  }

  appendTemplate(withoutButton = false) {
    let newTemplate = this.template.cloneNode(true);
    if (withoutButton) {
      let removeListing = newTemplate.querySelector(`.${this.removeListingButtonClass}`);
      removeListing.parentElement.removeChild(removeListing);
    }
    newTemplate.id += this.counter;
    this.container.appendChild(newTemplate);
    newTemplate.outerHTML = newTemplate.outerHTML.replace(/<id>/g, this.counter);
    this.counter++;
  }

  removeTemplate(e) {
    if (e && e.target && e.target.classList && e.target.classList.contains(this.removeListingButtonClass)) {
      let elementToRemove = e.target.closest('.' + this.templateClassName);
      elementToRemove.parentElement.removeChild(elementToRemove);
    }
  }

  hideIsRentalRadioButtons() {
    this.hideShowIsRentalRadioButtons(true);
  }

  showIsRentalRadioButtons() {
    this.hideShowIsRentalRadioButtons(false);
  }

  hideShowIsRentalRadioButtons(hide) {
    let displayedButtons = document.getElementsByClassName('tgl-new-listing-is-rental') || [];
    let templateButton = this.template.getElementsByClassName('tgl-new-listing-is-rental')[0];
    [...displayedButtons].forEach(displayedButton => {
      if (hide) {
        displayedButton.style.display = "none";
        templateButton.style.display = "none"
      } else {
        displayedButton.style.display = "block";
        templateButton.style.display = "block";
      }
    });
  }
}

export default ListingSetting;
