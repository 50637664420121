import {initStepperFormListeners} from './stepperForm';
import {initListingFormListeners} from './listingForm';
import {initUploadImageListeners} from './uploadImage';
import {initAllDayListeners} from './allDay';

function initListeners() {
  window.addEventListener('load', function () {
    initStepperFormListeners();
    initListingFormListeners();
    initAllDayListeners();
    initUploadImageListeners();
  });
}

export default initListeners;
