import {changeImageSrcOnUploadInputChange} from "../components/uploadImage";
import {UPLOAD_IMAGE_CONTAINER_ID} from "../constants/uploadImage";

function initUploadImageListeners() {
  uploadImageChangeListener();
}

function uploadImageChangeListener() {
  $(`#${UPLOAD_IMAGE_CONTAINER_ID}`).children('input').change(function () {
    changeImageSrcOnUploadInputChange(this);
  });
}

export {initUploadImageListeners}
